<template>
	<div class="out">
		<div class="header">
			<div class="logo Title">
				
				<div class="Chinese"><img
			src="../assets/img/return_index.png"
			class="return1"
			alt=""
			@click="$router.back()"
        	/>{{$t('main.accauth')}}</div>
			</div>
			<div class="right-icon">
				<img src="../assets/img/logo-icon.png" alt="">
				<div class="cc-wc">
					{{$t('main.titleCenter')}}
				</div>
			</div>
		</div>
		<div class="content">
			<div class="zb-edit">
				<div class="zb-pic zb-edit-item">
					<div class="zb-edit-item-top">
						<div class="title-ltfe">
							{{$t('main.accauth')}}
						</div>
					</div>
					<div class="step step1">
						<img class="top-text" src="../assets/img/step-icon1.png" alt="">
						<div class="mark-flex">
							<div class="mark-out">
								<div class="mark-in">
									
								</div>
							</div>{{$t('main.aystep')}}
						</div>
						
					</div>
					<div class="line-x line-x1">
						
					</div>
					<div class="step step2">
						<img class="top-text" src="../assets/img/step-icon2.png" alt="">
						<div class="mark-flex">
							<div class="mark-out">
								<div class="mark-in">
									
								</div>
							</div>{{$t('main.allstep2')}}
						</div>
						
						<div class="qr-code" id="qrcode">

						</div>
					</div>
					<div class="line-x line-x2">
						
					</div>
					<div class="step step3">
						<img class="top-text" src="../assets/img/step-icon3.png" alt="">
						<div class="mark-flex">
							<div class="mark-out">
								<div class="mark-in">
									
								</div>
							</div>{{$t('main.sqstep3')}}
						</div>
						
					</div>
					<div class="sq-tips-text">
						{{$t('main.noteAuth')}}
						<br>
						{{$t('main.qrcode5')}}
					</div>
				</div>

				<div class="submint-info" @click="goBack">
					{{$t('main.cta')}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import QRCode from 'qrcodejs2'
	export default {
		data() {
			return {
				qrcode: ''
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			async init() {
				await this.axios.post('/auth/createBind').then((res) => {
					
					this.qrcode = res.data.data.qr_code
				})
				

				let url = location.protocol+'//' + location.host
				new QRCode(document.getElementById('qrcode'), {
					text: url + '/api/auth/login?url=' + url + '/banding?qr_code=' + this.qrcode, //要生成二维码的网址
					width: 256, //图像宽度
					height: 256, //图像高度
					colorDark: '#000000', //前景色
					colorLight: '#ffffff', //背景色
					margin: 0, //外边距
					correctLevel: QRCode.CorrectLevel.L //容错级别。属性值有：QRCode.CorrectLevel.L、QRCode.CorrectLevel.M、QRCode.CorrectLevel.Q、QRCode.CorrectLevel.H
				})


			},
			goBack(){
				this.$router.push('/home')
			}
		}
	}
</script>

<style scoped>
	.out {
		padding: 0rem 0.15rem;
		min-height: 100vh;
		box-sizing: border-box;
		background: url(../assets/img/in-bg.jpg) no-repeat center;
		background-size: cover;
	}
	
	.header {
		display: flex;
		justify-content: space-between;
		/* line-height: 0; */
		margin-bottom: 0rem;
		margin-bottom: 0rem;
		margin: 0 -0.15rem;
		padding: 0 0.2rem;
		align-items: center;
		margin-bottom: 0;
		box-shadow: 0px 5px 15px -7px rgba(0, 0, 0, 0.3);
		    padding-bottom: 0.09rem;
		    padding-top: 0.05rem;
			background: #FFFFFF;
	}

	.Title {
		font-size: 0.16rem;
		position: relative;
	}

	.English {
		    color: #d6eedc;
		    position: absolute;
		    top: 0.1rem;
		    left: 0.15rem;
	}
	
	.Chinese {
		color: #1b6a21;
		/* top: -0.2rem; */
		left: 0;
		position: relative;
		text-align: left;
		font-size: 0.18rem;
	}

	.cc-wc {
		font-size: 0.1rem;
		color: #e9630a;
	}
	
	.right-icon {
		/* width: 1rem; */
		display: flex;
		    flex-direction: column;
		    align-items: flex-end;
	}

	.right-icon img {
		display: block;
		width: 100%;
		height: 100%;
		    width: 0.65rem;
	}

	.zb-edit {
		font-size: 0.16rem;
		padding-bottom: 0.23rem;
		padding: 0.15rem;
		    background: #FFF;
			padding-bottom: 0.25rem;
	}

	.zb-edit-item {
		margin: 0;
		padding: 0;
		background: #FFFFFF;
		/* box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3); */
		/* margin-bottom: 0.17rem; */
		padding-top: 0;
		    padding-bottom: 0.4rem;
			position: relative;
	}

	.zb-edit-item-top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: #9E9E9E;
		margin-bottom: 0.21rem;
	}

	.step {
		color: #343434;
		font-size: 0.15rem;
		margin-bottom: 0.22rem;
		display: flex;
		flex-direction: column;
	}
	.step3{
		margin-bottom: 0.1rem;
	}

	.qr-code {
		margin-top: 0.29rem;
		text-align: center;
		display: flex;
		justify-content: center;
	}

	.qr-code img {
		/* display: block; */
	}

	.title-ltfe {
		font-size: 0.18rem;
		color: #343434;
	}

	.title-right {
		font-size: 0.12rem;
	}

	.zb-edit-item-bottom input {
		height: 0.35rem;
		width: 100%;
		outline: none;
		margin-bottom: 0.15rem;
		border-radius: 6px;
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
		border: none;
		padding-left: 0.1rem;
		box-sizing: border-box;
	}

	.sq-box {
		display: flex;
		align-items: center;
		margin-bottom: 0.26rem;
		color: #9E9E9E;
		font-size: 0.14rem;
		justify-content: space-between;
	}

	.sq-box .sq-btn {
		width: 1.16rem;
		height: 0.4rem;
		line-height: 0.4rem;
		text-align: center;
		border-radius: 0.1rem;
		background: #3662EC;
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
		color: #FFFFFF;
	}

	.sq-tips-text {
		display: flex;
		/* justify-content: flex-end; */
		align-items: center;
		color: #9E9E9E;
		font-size: 0.14rem;
		margin-bottom: 0;
	}

	.agree-box {
		display: flex;
		align-items: center;
	}

	.agree-box span {
		font-size: 0.14rem;
		font-weight: 500;
		letter-spacing: 0px;
		color: #9E9E9E;
		margin-left: 0.1rem
	}

	.submint-info {
		width: 100%;
		height: 0.5rem;
		    line-height: 0.5rem;
		text-align: center;
		color: #FFFFFF;
		border-radius: 5px;
		background: #2d71d7;
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
		/* margin-top:0.23rem; */
		margin-bottom: 0;
	}
	.top-text{
		width: 0.285rem;
		margin-bottom: -0.08rem;
		    margin-left: 0.25rem;
	}
	.mark-flex{
		display: flex;
		align-items: center;
	}
	.mark-out{
		width: 0.14rem;
		height: 0.14rem;
		border: 1px #2d71d7 solid;
		border-radius: 50%;
		padding: 0.02rem;
		box-sizing: border-box;
		margin-right: 0.05rem;
	}
	.mark-in{
		width: 100%;
		height: 100%;
		background: #2d71d7;
		border-radius: 50%;
	}
	.line-x{
		border-left: 2px dashed #2d71d7;
		    
		    position: absolute;
		    left: 0.06rem;
	}
	.line-x.line-x1{
		    height: 0.38rem;
		    top: 0.87rem;
	}
	.line-x.line-x2{
		height: 326px;
		    top: 1.45rem;
	}
</style>
